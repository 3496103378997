import { createLazyLoadingObserver } from './lazy-loading-observer.js';

export const lazyAttributes = [`src`, `srcset`, `style`, `sizes`];

export function replaceLazyDataAttribute(attributes, el) {
  attributes.forEach((attr) => {
    const value = el.dataset[attr];

    if (value) {
      el[attr] = value;
      delete el.dataset[attr];
    }
  });
}

export const getLazyResources = (parent = document) =>
  parent.querySelectorAll(lazyAttributes.map((attr) => `[data-${attr}]`).join(`,`));

export const observeResourcesInContainer = (observer) => ($container) =>
  getLazyResources($container).forEach(($source) => observer.observe($source));

export function observeLazySources($root = document) {
  const lazyObserver = createLazyLoadingObserver(({ target = {} } = {}) => {
    replaceLazyDataAttribute(lazyAttributes, target);
    lazyObserver.unobserve(target);
  });

  getLazyResources($root).forEach((source) => lazyObserver.observe(source));

  return lazyObserver;
}
