import { getViewportHeight } from '../services/viewport';

export function createLazyLoadingObserver(loadCallback = () => {}) {
  return new IntersectionObserver(
    (changes) => {
      changes.filter((change) => change.isIntersecting).forEach(loadCallback);
    },
    { threshold: 0, rootMargin: `${getViewportHeight()}px 0px ${getViewportHeight()}px 0px` },
  );
}
