import { v4 as uuidV4 } from 'uuid';

const STORAGE_FIELDS = {
  id: `recommendation_uuid`,
  timestamp: `recommendation_uuid_timestamp`,
};

const getCurrentUuid = () => {
  let uuid = window.localStorage.getItem(STORAGE_FIELDS.id);

  if (!uuid) {
    uuid = uuidV4();
    window.localStorage.setItem(STORAGE_FIELDS.id, uuid);
    window.localStorage.setItem(STORAGE_FIELDS.timestamp, +new Date());
  }

  return uuid;
};

export const Viewer = {
  id: getCurrentUuid(),
};
