import { observeLazySources } from './modules/lazy-sources-loader.js';
import { Viewer } from './modules/user';
import { isMobile } from './services/viewport';

/* need for old browsers without defer */
document.addEventListener(
  `DOMContentLoaded`,
  () => {
    if (FEATURES.RECOMMENDATION) {
      import(`./modules/recommendation`).then(({ getRecommendation, render }) => {
        getRecommendation(Viewer.id, isMobile() ? 5 : 3).then((data) => {
          render(data);
        });
      });
    }

    /* initialization of lazy loading */
    observeLazySources();
  },
  false,
);
